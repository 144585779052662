import axios from "axios";

export async function get_latest_news() {
    let response = await axios.get("https://www.wired.com/feed/tag/ai/latest/rss");
    let parser = new DOMParser();
    let xml = parser.parseFromString(response.data, "application/xml");

    if (xml.getElementsByTagName("parsererror").length) {
        throw new Error("Error while parsing XML");
    }

    let extracted_items = Array.from(xml.getElementsByTagName("item")).map(item => ({
        title: item.getElementsByTagName("title")[0]?.textContent,
        date: new Date(item.getElementsByTagName("pubDate")[0]?.textContent).getTime() / 1000,
        link: item.getElementsByTagName("link")[0]?.textContent,
        image: item.getElementsByTagName("media:thumbnail")[0]?.getAttribute("url") || "",
        summary: item.getElementsByTagName("description")[0]?.textContent
    }));

    return extracted_items;
}

export async function register_email(email) {
    let response = await axios.post('https://api.butcoin.xyz/analytics/register_email', { email });
    return response.data;
}