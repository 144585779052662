import {Helmet} from "react-helmet";

import axios from "axios";

import coins_map from "../content/reviews/data/coins_map.json";
import tick_icon from "../images/icons/tick.png";

// ---- Images ----
import main_0 from "../images/articles/JetBolt/main_0.png";
import thin_banner_0 from "../images/articles/JetBolt/thin_banner_0.png";
import screenshot_1 from '../images/articles/JetBolt/screenshot_1.png';
import crypto_news from "../images/articles/crypto_news.png"

// ---- Services ----
import { get_country_code } from "./location";

let endpoint = "https://cryptocat.ai";

let ticker_map = {
    "ETH": "Ethereum",
    "SOL": "Solana",
    "SHIB": "Shiba Inu",
    "BTC": "Bitcoin",
    "KAS": "Kaspa"
}

let ticker_img_map = {
    "ETH": "https://pbs.twimg.com/profile_banners/2312333412/1676895216/1500x500",
    "SOL": "https://pbs.twimg.com/profile_banners/951329744804392960/1718431291/1500x500",
    "SHIB": "https://pbs.twimg.com/profile_banners/1356248804899565569/1680554268/1500x500",
    "BTC": "https://pbs.twimg.com/profile_banners/357312062/1566845268/1500x500",
    "KAS": "https://pbs.twimg.com/profile_banners/1459142382381932549/1661182082/1500x500"
}

let country;


let merch_link = "https://httpstechnownews.creator-spring.com/listing/wen-moon-tnn";

export async function make_ticker_news(ticker) {

    let token = coins_map["JetBolt"];

    let img = require(`../images/articles/tickers/${ticker.toLowerCase()}.png`);

    function handle_outlink() {

        let outlink = `https://jetbolt.io/?source=tnn_ticker_${ticker.toLowerCase()}_${country.toLowerCase()}`

        // window.gtag('event', 'conversion', {
        //     'send_to': "AW-16555645576/eGnuCM_k9bwZEIi1rNY9",
        //     value: 1.0,
        //     currency: 'USD',
        // });

        window.open(outlink);
    }


    // let response = await axios.post(`${endpoint}/news/latest_ticker`, { ticker });
    // let { intro, conclusion, bullets } = response.data;

    let { intro, conclusion, bullets, history, technology } = require(`./data/${ticker}_data.json`);

    // bullets.push(bullets[2]);
    // bullets[2] = "New Altcoin JetBolt is growing rapidly after early presale success"
    let data = {
        title: `Latest ${ticker_map[ticker]} News`,
        date: Math.floor(Date.now()/1000) - 4340,
        image: img,
        summary: intro,
        link: `ticker/${ticker.toLowerCase()}/latest-news`,
        content: <>
            {/* <div className="fw_split_box purple" onClick={()=>window.location=merch_link}>Buy Apparel</div>
            <div className="fw_split_box gray" onClick={()=>window.location=merch_link}>Explore Shop</div>
            <section>
                <h2>{ticker} History</h2><br/>
                <p>{history}</p><br/>
            </section>
            <section>
                <h2>{ticker} Technology</h2><br/>
                <p>{technology}</p><br/>
            </section> */}
            <section>
                {/* <h2>{ticker} Recent Updates</h2><br/> */}
                {
                    bullets.map((item,index)=>(
                        <div key={index}>
                            {
                                index == 2 &&
                                <p>&#8226; New <u className="highlighted_text" onClick={handle_outlink}>Altcoin JetBolt is growing rapidly</u> as its presale gains rapid traction. With game changing new features such as zero gas fees, AI, and easy to earn crypto staking, this new coin should be closely watched in 2024. Powered by the Skale Network, JetBolt hopes to provide an invisible layer between applications and blockchain.<br/><br/></p>
                            }
                            <p>&#8226; {item}<br/><br/></p>
                        </div>
                    ))
                }
            </section>
            <section>
                <h2>What is JetBolt?</h2><br/>
                <p>JetBolt is revolutionary new cryptocurrency gaining traction in the news. JetBolt harnesses the power of zero gas technology with the Skale network, and this trending new coin also includes its own ecosystem that enables developers to create <b>zero gas applications.</b></p><br/>
                <p>JetBolt has a wide range of features including Artificial Intelligence Utility, lightning fast transactions, a staking platform where stakers can earn crypto rewards, and more. It's rapidly growing crypto presale is attracting increasing attention. Learn more about JetBolt at the link below.</p>
                <br/>
                {/* <img style={{width: "100%", cursor: "pointer"}} className="selectable_image" src={thin_banner_0}  onClick={handle_outlink}/>
                <br/><br/>
                <u className="highlighted_text"  onClick={handle_outlink}><h3>Learn More About JetBolt</h3></u>
                <br/> */}
                <div className={`token_box blue`}>
                    <div className={`rank_indicator_simple blue`}>★</div>
                    <div className={`rank_indicator_extra_text blue`}>{token.header_title}</div>
                    <div className="token_box_desc">
                        <h2>
                            <img className="token_icon" src={token.icon}/>
                            {token.name}
                        </h2>
                    </div>
                    <div className="vs_button" onClick={handle_outlink}>Learn More</div>
                    <div className="extra_spacer"></div>
                    {
                        token.usps.map((usp, index)=>(
                            <div className="tick_reason" key={index}>
                                <img className="tick_icon" src={tick_icon}/>
                                {usp}
                            </div>
                        ))
                    }
                    <div className="vs_button_mobile" onClick={handle_outlink}>Learn More</div>
                </div>
            </section>
            <section>
                <h2>{ticker} Price Chart</h2><br/>
                <p>{intro}</p><br/>
                <gecko-coin-price-chart-widget locale="en" coin-id={ticker_map[ticker].toLowerCase().replaceAll(" ","-")} initial-currency="usd" outlined="true"></gecko-coin-price-chart-widget>
                <br/>
            </section>
            <section>
                <h2>Why is new coin JetBolt Gaining Attention?</h2><br/>
                <p>JetBolt is quickly gaining attention with its meteoric crypto presale growth. Crypto buyers are attracted to JetBolt due to its highly revolutionary and appealing features, including:</p><br/>
                <p><strong>&#8226; Zero Gas Fees:</strong> Say goodbye to hefty transaction fees commonly associated with Ethereum. JetBolt introduces zero gas fees, allowing users to transact without additional costs.</p><br/>
                <p><strong>&#8226; Easy To Earn Crypto Staking:</strong> JetBolt makes earning crypto via staking straightforward and rewarding. The platform leverages a Proof of Attendance protocol that rewards the most active staking holders, ensuring those who participate the most, benefit the most. You can also earn more rewards by connecting with your friends.</p><br/>
                <p><strong>&#8226; AI (Artificial Intelligence) Powered Utility:</strong> JetBolt includes an AI powered crypto news and insights tool which helps users learn about emerging crypto trends by market sentiment.</p>
                <br/>
                <img style={{width: "100%", cursor: "pointer"}} className="selectable_image" src={"https://i.imgur.com/bMA9Jh0.png"} onClick={handle_outlink}/>
                <section className="full_token_section">
                    
                    {/* <div className="extra_spacer"></div> */}
                    <table className="full_table">
                        <tr>
                            <th>Name</th>
                            <td>{token.name}</td>
                        </tr>
                        <tr>
                            <th>Symbol</th>
                            <td>{token.symbol}</td>
                        </tr>
                        <tr>
                            <th>Chain</th>
                            <td>{token.blockchain}</td>
                        </tr>
                        <tr>
                            <th>Launch Date</th>
                            <td>{token.launch_date}</td>
                        </tr>
                        {
                            token.is_sponsored &&
                            <tr>
                                <th>Official Site</th>
                                <td><u className="highlighted_text" onClick={handle_outlink}>{token.official_site}</u></td>
                            </tr>
                        }
                    </table>
                    {
                        token.is_sponsored &&
                        <div className="visit_nsite_button" onClick={handle_outlink}>Visit {token.name} Site</div>
                    }
                </section>
            </section>
            <section>
                <h2>Conclusion</h2><br/>
                <p>{conclusion}</p><br/>
                <p>Meanwhile, <u className="highlighted_text" onClick={handle_outlink}>new token JetBolt</u> is rapidly emerging as a serious altcoin contender. With a miryad of features and a growing community it is worth keeping your eye on this coin.</p>
            </section>
        </>
    }

    return data;
}

export async function make_latest_news() {

    let token = coins_map["JetBolt"];

    function handle_outlink() {

        let outlink = `https://jetbolt.io/?source=tnn_latest_news_${country.toLowerCase()}`

        // window.gtag('event', 'conversion', {
        //     'send_to': "AW-16555645576/eGnuCM_k9bwZEIi1rNY9",
        //     value: 1.0,
        //     currency: 'USD',
        // });

        window.open(outlink);
    }

    // let response = await axios.post(`${endpoint}/news/latest_news`);
    // let { intro, conclusion, bullets } = response.data;

    let { intro, conclusion, bullets, history, technology } = require(`./data/latest_news_data.json`);
    

    let data = {
        title: `Latest Crypto News`,
        date: Math.floor(Date.now()/1000) - 4340,
        image: crypto_news,
        summary: intro,
        link: `all/latest-news`,
        content: <>
            {/* <div className="fw_split_box purple" onClick={()=>window.location=merch_link}>Buy Apparel</div>
            <div className="fw_split_box gray" onClick={()=>window.location=merch_link}>Explore Shop</div>
            <section>
                <h2>Blockchain History</h2><br/>
                <p>{history}</p><br/>
            </section>
            <section>
                <h2>Blockchain Technology</h2><br/>
                <p>{technology}</p><br/>
            </section> */}
            <section>
                {/* <h2>Blockchain Recent Updates</h2><br/> */}
                {
                    bullets.map((item,index)=>(
                        <div key={index}>
                            {
                                index == 2 &&
                                <p>&#8226; New <u className="highlighted_text" onClick={handle_outlink}>Altcoin JetBolt is growing rapidly</u> as its presale kicks off. With game changing new features such as zero gas fees, AI, and easy to earn crypto staking, this new coin should be closely watched in 2024. Powered by the Skale Network, JetBolt hopes to provide an invisible layer between applications and blockchain.<br/><br/></p>
                            }
                            <p>&#8226; {item}<br/><br/></p>
                        </div>
                    ))
                }
            </section>
            <section>
                <h2>What is JetBolt?</h2><br/>
                <p>JetBolt is revolutionary new cryptocurrency gaining traction in the news. JetBolt harnesses the power of zero gas technology with the Skale network, and this trending new coin also includes its own ecosystem that enables developers to create <b>zero gas applications.</b></p><br/>
                <p>JetBolt has a wide range of features including Artificial Intelligence Utility, lightning fast transactions, a staking platform where stakers can earn crypto rewards, and more. It's rapidly growing crypto presale is attracting increasing attention. Learn more about JetBolt at the link below.</p>
                {/* <br/>
                <img style={{width: "100%", cursor: "pointer"}} className="selectable_image" src={thin_banner_0}  onClick={handle_outlink}/>
                <br/><br/>
                <u className="highlighted_text"  onClick={handle_outlink}><h3>Learn More About JetBolt</h3></u>
                 */}
                <br/>
                <div className={`token_box blue`}>
                    <div className={`rank_indicator_simple blue`}>★</div>
                    <div className={`rank_indicator_extra_text blue`}>{token.header_title}</div>
                    <div className="token_box_desc">
                        <h2>
                            <img className="token_icon" src={token.icon}/>
                            {token.name}
                        </h2>
                    </div>
                    <div className="vs_button" onClick={handle_outlink}>Learn More</div>
                    <div className="extra_spacer"></div>
                    {
                        token.usps.map((usp, index)=>(
                            <div className="tick_reason" key={index}>
                                <img className="tick_icon" src={tick_icon}/>
                                {usp}
                            </div>
                        ))
                    }
                    <div className="vs_button_mobile" onClick={handle_outlink}>Learn More</div>
                </div>
            </section>
            <section>
                <h2>Latest Price Charts</h2><br/>
                <p>{intro}</p><br/>
                <gecko-coin-compare-chart-widget locale="en" outlined="true" coin-ids="" initial-currency="usd"></gecko-coin-compare-chart-widget>
                <br/>
            </section>
            <section>
                <h2>Why is JetBolt Gaining Attention?</h2><br/>
                <p>JetBolt is quickly gaining attention with its meteoric crypto presale growth. Crypto buyers are attracted to JetBolt due to its highly revolutionary and appealing features, including:</p><br/>
                <p><strong>&#8226; Zero Gas Fees:</strong> Say goodbye to hefty transaction fees commonly associated with Ethereum. JetBolt introduces zero gas fees, allowing users to transact without additional costs.</p><br/>
                <p><strong>&#8226; Easy To Earn Crypto Staking:</strong> JetBolt makes earning crypto via staking straightforward and rewarding. The platform leverages a Proof of Attendance protocol that rewards the most active staking holders, ensuring those who participate the most, benefit the most. You can also earn more rewards by connecting with your friends.</p><br/>
                <p><strong>&#8226; AI (Artificial Intelligence) Powered Utility:</strong> JetBolt includes an AI powered crypto news and insights tool which helps users learn about emerging crypto trends by market sentiment.</p>
                <br/>
                <img style={{width: "100%", cursor: "pointer"}} className="selectable_image" src={"https://i.imgur.com/bMA9Jh0.png"} onClick={handle_outlink}/>
                <section className="full_token_section">
                    
                    {/* <div className="extra_spacer"></div> */}
                    <table className="full_table">
                        <tr>
                            <th>Name</th>
                            <td>{token.name}</td>
                        </tr>
                        <tr>
                            <th>Symbol</th>
                            <td>{token.symbol}</td>
                        </tr>
                        <tr>
                            <th>Chain</th>
                            <td>{token.blockchain}</td>
                        </tr>
                        <tr>
                            <th>Launch Date</th>
                            <td>{token.launch_date}</td>
                        </tr>
                        {
                            token.is_sponsored &&
                            <tr>
                                <th>Official Site</th>
                                <td><u className="highlighted_text" onClick={handle_outlink}>{token.official_site}</u></td>
                            </tr>
                        }
                    </table>
                    {
                        token.is_sponsored &&
                        <div className="visit_nsite_button" onClick={handle_outlink}>Visit {token.name} Site</div>
                    }
                </section>
            </section>
            <section>
                <h2>Conclusion</h2><br/>
                <p>{conclusion}</p><br/>
                <p>Meanwhile, <u className="highlighted_text" onClick={handle_outlink}>new token JetBolt</u> is rapidly emerging as a serious altcoin contender. With a miryad of cutting-edge features and a growing community interest, it is worth keeping your eye on this coin.</p>
            </section>
        </>
    }

    return data;
}

(async function () {
    try {
        country = await get_country_code();
    } catch (err) {}
})();