import { useState, useEffect } from 'react';

// ---- Constants ----
import colors from "../constants/colors";

// ---- Hooks ----
import { useWindowWidth } from '../services/window_width_provider';

function Footer() {

    const window_width = useWindowWidth();

    let styles = {

        container: {
            position: "relative",
            width: "100%",
            marginTop: "20px",
            backgroundColor: "black",
            color: "white"
        },

        inner_container: {
            position: "relative",
            width: "1200px",
            marginLeft: "calc(50% - 600px)",
            height: "100%",
            paddingTop: "20px",
            paddingBottom: "20px",
            fontSize: "14px",

            ...(window_width <= 1240 && {
                width: "calc(100% - 40px)",
                marginLeft: "20px",
            })
        },
    
    }

    return (
        <div style={styles.container}>
            <div style={styles.inner_container}>
                <h3>Disclaimer</h3>
                <p>We strive to provide accurate and timely information, but cannot guarantee that all content on this site is correct or up-to-date. Our articles and reports are intended for informational purposes only, and we do not endorse any specific products or investments. Readers are advised to verify the facts and conduct their own research before making any decisions based on the information provided here.</p>
                <br/>
                <p>© 2023 Tech Now News. All Rights Reserved.</p>
                <br/>
                <p><u className="clickable_text" onClick={()=>window.location="/terms"}>Terms of Use</u> | <u className="clickable_text" onClick={()=>window.location="/privacy"}>Privacy Policy</u></p>
            </div>
        </div>
    )
}

export default Footer;