import { useState, useEffect } from 'react';

let map = {
    "open_purchase_modal": "AW-16700876044/fMKaCM3VkdMZEIzKzJs-",
    "begin_checkout": "AW-16700876044/kn7mCNDVkdMZEIzKzJs-",
    "connect_wallet": "AW-16700876044/t8OjCNLakdMZEIzKzJs-",
    "purchase": "AW-16700876044/IyBICIqUpNIZEIzKzJs-"
}

function Purchase() {

    useEffect(()=> {

        let url = new URL(window.location.href);
        let url_params = url.searchParams;
        let name = url_params.get("name");
        let value = url_params.get("value");

        if (name) {

            window.gtag('event', 'conversion', {
                'send_to': map[name],
                'value': value,
                'currency': 'USD',
                'transaction_id': ''
            });

            console.log("SENT", { name, value })

        }

    },[]);


    return (
        <>Success!</>
    )
}

export default Purchase